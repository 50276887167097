import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'

import Link from '../components/link'
import SEO from '../components/seo'
import Layout from '../components/layout'
import ContactForm from '../components/ContactForm'
import Team from '../components/Team'
import Expertise from '../components/Expertise'
import Purpose from '../components/Purpose'

import HeaderImage from '../assets/svg/headerImage.svg'

function IndexPage() {
    const { t } = useTranslation('homepage')

    return (
        <Layout>
            <SEO title="homepage:title" />
            <div className="welcomeHeader">
                <div className="welcomeHeaderTitles">
                    <h1 className="homeTitle">{t('homeTitle')}</h1>
                    <p>{t('homeSubtitle')}</p>
                    <Button
                        size="large"
                        to="/#contact"
                        component={Link}
                        disableElevation
                        className="contactButton"
                    >
                        Contact us
                    </Button>
                </div>
                <HeaderImage className="headerImage" />
            </div>
            <Purpose />
            <Expertise />
            <Team />
            <ContactForm />
        </Layout>
    )
}

export default IndexPage
